import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';

import ReservationApp from './ReservationApp';
import ArticlesPopupHeader from './Articles/ArticlesPopup/ArticlesPopupHeader';

import reservationReducer, { setReservationId } from './store/reservationSlice';
import inplaceEditorReducer from './store/inplaceEditorSlice';
import articlesPopupReducer, { setArticlesPopupInitialDate } from './store/articlesPopupSlice';

import ReservationApi from "./api/ReservationApi";

// the interface between js app and react app
window.quickverleih.app = (function() {
    // if needed to interact with the react app, use this store
    let reservationStore = null;

    function init_reservation_details(reservation_id) {

        reservationStore = configureStore({
            reducer: {
              reservation: reservationReducer,
              inplaceEditor: inplaceEditorReducer,
              articlesPopup: articlesPopupReducer
            }
        })

        if(process.env.NODE_ENV === 'development') {
            window.quickverleih.app.reservation_store = reservationStore;
        }

        reservationStore.dispatch(setReservationId(reservation_id));

        ReactDOM.render(
            <Provider store={reservationStore}>
                <ReservationApp />
            </Provider>,
            document.getElementById("reservation_app")
        );
    }

    function new_reservation(contract_type) {
        const onCustomerSelected = async (customer_id) => {
            const response = await ReservationApi.newContractFor(customer_id, contract_type);
            if(response.success) {
                window.location.replace(response.redirect_to);
                return;
            }
        }
        
        window.openCustomerTable(null, onCustomerSelected);
    }
    
    function init_categories_select(customerTenantId, articleTenantId, start_date, start_time, end_date, end_time, filter, specFilter, locationId) {

        reservationStore = configureStore({
            reducer: {
              reservation: reservationReducer,
              inplaceEditor: inplaceEditorReducer,
              articlesPopup: articlesPopupReducer
            }
        })

        if(process.env.NODE_ENV === 'development') {
            window.quickverleih.app.reservation_store = reservationStore;
        }

        let initialData = {
            customerTenantId: customerTenantId,
            articleTenantId: articleTenantId,
            start_date: start_date,
            start_time: start_time,
            end_date: end_date,
            end_time: end_time,
            filter: filter,
            specFilter: specFilter,
            locationId: locationId
        }
        reservationStore.dispatch(setArticlesPopupInitialDate(initialData));

        ReactDOM.render(
            <Provider store={reservationStore}>
                <ArticlesPopupHeader />
            </Provider>,
            document.getElementById("categories_selector")
        );
    }

    return {
        init_reservation_details,
        new_reservation,
        init_categories_select
    };
})();