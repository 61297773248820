
import React from "react";
import PropTypes from 'prop-types';
import classNames from "classnames";

const ActionButton = (props) => {
    const cssClasses = classNames(["action_button", props.className]);

    return (
        <div className={cssClasses} onClick={props.onClick}>{props.text}</div>
    );
}

ActionButton.propTypes = {
    className: PropTypes.string,
    text: PropTypes.string.isRequired,

    onClick: PropTypes.func.isRequired
}

export default ActionButton;