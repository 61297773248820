/* global send_email */

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Separator from "../common/components/Separator";
import SvgButton from "../common/components/SvgButton";

import lang from "../common/lang/de.json";

import {
    selectIsReservation,
    selectReservationCustomer,
    selectReservationHeader,
    setNewCustomer,
    selectReservationArticles,
} from './store/reservationSlice';

const ReservationCustomer = () => {
    const dispatch = useDispatch();
    
    const isReservation = useSelector(selectIsReservation);
    const reservationHeader = useSelector(selectReservationHeader);
    const reservationCustomer = useSelector(selectReservationCustomer);
    const reservationArticles = useSelector(selectReservationArticles);
    
    const isAnonymous = reservationHeader && reservationHeader.anonymous == 1
    const collectorIdNumber = reservationHeader && reservationHeader.studierenden_nr

    const customerName = reservationCustomer ? reservationCustomer.firma : "";
    const customerEmail = reservationCustomer ? reservationCustomer.email : "";
    const callbackNumber = reservationCustomer ? reservationCustomer.ruckrufnr : "";

    const onCustomerSelected = (customerId) => {
        dispatch(setNewCustomer(customerId));
    }
    
    const changeCustomer = () => {
        let articleTenantId = null;
        if(reservationArticles && reservationArticles.length) {
            articleTenantId = reservationArticles[0].tenant_id;
        }
        console.log("articleTenantId: ", articleTenantId);
        window.openCustomerTable(articleTenantId, onCustomerSelected);
    }

    return (
        <div className="customer">
            <span className="name_label">{lang.reservation.customer}:</span>{customerName}
            {!isAnonymous &&
            <>
                <Separator />
                {customerEmail}
                
                {callbackNumber !== "" &&
                    <>
                        <Separator />
                        {lang.callback_number}: {callbackNumber}
                    </>
                }

                {collectorIdNumber !== "" &&
                    <>
                        <Separator />
                        {lang.collector_id_number}: {collectorIdNumber}
                    </>
                }

                <Separator />
                <SvgButton svgRef="icon-knob" textRight={lang.reservation.change_customer} onClick={changeCustomer} />

                <Separator />
                <SvgButton svgRef="icon-mail" textRight={isReservation ? lang.reservation.send : lang.request.send} onClick={() => send_email(reservationCustomer.customer_address_id) } />
            </>
            }
        </div>
    );
}

export default ReservationCustomer;