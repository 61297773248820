import React from 'react';
import { useSelector } from 'react-redux';
import DOMPurify from 'dompurify';

import LinkButton from "../common/components/LinkButton";
import pdfLink from "./PDFLink";

import lang from "../common/lang/de.json"

import { selectReminders } from "./store/reservationSlice";

const ReservationReminders = () => {
    const reminders = useSelector(selectReminders);

    const renderReminderArticles = (reminderArticles) => {
        let reminderArticleEntries = [];

        var key = 0;
        for(var reminderArticle of reminderArticles) {
            const htmlArticleName = `${reminderArticle.full_article_number} (${reminderArticle.name})`;
            
            reminderArticleEntries.push(
                <div key={key++} className="article_entry" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(htmlArticleName) }} />
                
            );           
        }

        return reminderArticleEntries;
    }

    const renderReminders = (reminders) => {
        let remindersTable = [];

        // the reminders is an array indexes on reminder step, mapping the step to the reminders for that step
        for(var step in reminders) {
            let remindersForStep = reminders[step];

            var firstForStep = true;

            // the reminders for each step, is an array mapping the uid of the reminder with the reminder data
            for(var reminderUid in remindersForStep) {
                let reminder = remindersForStep[reminderUid];

                remindersTable.push(
                    <div key={step + "_" + reminder.uid} className="reminder_entry">
                        <div className="reminder_step">
                            {firstForStep ? lang.reminder_step + " " + reminder.step : ""}
                        </div>
                        <div className="reminder_date">{reminder.date}</div>
                        <div className="reminder_articles">{renderReminderArticles(reminder.articles)}</div>
                        <div className="reminder_actions">
                            <LinkButton textLeft="" textRight={lang.show} link={pdfLink("reminder", reminder.uid)} />
                        </div>
                    </div>
                );

                firstForStep = false;
            }
        }

        return remindersTable;
    }

    return (
        <div className="dash-block-container">
            <div className="dash-block-header text-uppercase">{lang.reservation.reminders_title}</div>

            <div id="reminders" className="qv_table">
                <div className="qv_table_header">
                    <div className="reminder_step">{lang.reservation.reminders_step}</div>
                    <div className="reminder_date">{lang.reservation.reminders_date}</div>
                    <div className="reminder_articles">{lang.reservation.reminders_overdues_articles}</div>
                    <div className="reminder_actions"></div>
                </div>

                <div className="qv_table_content">
                    {reminders && 
                        renderReminders(reminders)
                    }
                </div>
            </div>
        </div>
    );
}

export default ReservationReminders;