import React from "react";
import PropTypes from 'prop-types';
import classNames from "classnames";
import Select from 'react-select'
import "../scss/DropdownSelect.scss";

const DropdownSelect = (props) => {
    const cssClasses = classNames(["dropdown_select", props.className]);
    const style = {
        option: (styles, state) => ({
            ...styles,
            color: state.isSelected ? "#FFF" : styles.color,
            backgroundColor: state.isSelected ? "#6DC1B3" : styles.color,
            // borderBottom: "1px solid rgba(0, 0, 0, 0.125)",
            "&:hover": {
              color: "#000",
              backgroundColor: "#F9F9F9"
            }
        }),
        control: (styles, state) => ({
            ...styles,
            boxShadow: state.isFocused ? "0 0 0 0.1rem #CED4DA" : 0,
            borderColor: state.isFocused ? "#CED4DA" : "#CED4DA",
            "&:hover": {
              borderColor: state.isFocused ? "#CED4DA" : "#CED4DA"
            }
        })
    };

    return (
        <Select styles={style} className='react-select-container' classNamePrefix="react-select" isSearchable={false} {...props} />
    );
}

DropdownSelect.propTypes = {
    className: PropTypes.string,

    options: PropTypes.array.isRequired,

    onChange: PropTypes.func.isRequired
}

export default DropdownSelect;