import React from 'react';
import PropTypes from 'prop-types';

import "./../scss/popup.scss";

import lang from "../lang/de.json"

import ActionButton from './ActionButton';

const Popup = (props) => {
    return (
        <div className='popup_container'>
            <div className='popup_inner'>
                <div className="dash-block-header">{props.title}</div>

                <div className="popoup_content">
                    {props.children}
                </div>

                {props.showButtons &&
                    <div className="popup_buttons">
                        <ActionButton onClick={props.onOkClicked} text={props.okButtonLabel ?? lang.ok} className={props.okButtonClass ?? ""} />
                        <ActionButton onClick={props.onCancelClicked} text={lang.cancel} className="right_block gray" />
                    </div>
                }

                <div onClick={props.onCancelClicked}  className="close-poppup" />
            </div>
        </div>
    );
}

Popup.propTypes = {
    title: PropTypes.string.isRequired,
    okButtonLabel: PropTypes.string,
    okButtonClass: PropTypes.string,
    children: PropTypes.node,
    showButtons: PropTypes.bool,
    onOkClicked: PropTypes.func,
    onCancelClicked: PropTypes.func.isRequired
}

Popup.defaultProps = {
    showButtons: true
};

export default Popup;