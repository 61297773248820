import React from "react";
import PropTypes from 'prop-types';
import classNames from "classnames";
import '../scss/page-section.scss'

const PageSection = (props) => {
    const cssClasses = classNames(["page-section", props.className]);

    return (
        <div className={cssClasses}>
            {props.children}
        </div>
    );
}

PageSection.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node
}

export default PageSection;