
const findAndAddHandout = (collection, handout) => {
    let i;
    if(handout.subarticle_id == 0) {
        i = collection.findIndex(article => article.a_id == handout.article_id && article.recurrence_pos == handout.recurrence_pos && article.recurring_id == handout.recurring_id);
    } else {
        i = collection.findIndex(article => article.article_id == handout.article_id && article.subarticle_id == handout.subarticle_id &&
            article.recurrence_pos == handout.recurrence_pos && article.recurring_id == handout.recurring_id);
    }

    if(i !== -1) {
        if(!collection[i].handouts) {
            collection[i].handouts = [];
        }

        collection[i].handouts.push(handout);
    }
}

const rearangeHandoutsData = (articles_data) => {
    const articles = articles_data.articles;
    const subarticles = articles_data.subarticles;
    const handouts = articles_data.handouts;

    for(let handout of handouts) {
        // if this is a handout for an article, add it to the corresponding article, otherwise add it to the corresponding subarticle
        if(handout.subarticle_id == 0) {
            findAndAddHandout(articles, handout);
        } else {
            for(let articleId in subarticles) {
                findAndAddHandout(subarticles[articleId], handout);
            }            
        }
    }

    delete articles_data.handouts;

    return articles_data;
}

const findAndAddReturn = (collection, article_return) => {
    let i;
    if(article_return.subarticle_id == 0) {
        i = collection.findIndex(article => article.a_id == article_return.article_id && article.recurrence_pos == article_return.recurrence_pos && article.recurring_id == article_return.recurring_id);
    } else {
        i = collection.findIndex(article => article.article_id == article_return.article_id && article.subarticle_id == article_return.subarticle_id &&
            article.recurrence_pos == article_return.recurrence_pos && article.recurring_id == article_return.recurring_id);
    }

    if(i !== -1) {
        if(!collection[i].returns) {
            collection[i].returns = [];
        }

        collection[i].returns.push(article_return);
    }
}

const rearangeReturnsData = (articles_data) => {
    const articles = articles_data.articles;
    const subarticles = articles_data.subarticles;
    const returns = articles_data.returns;

    for(let article_return of returns) {
        // if this is a return for an article, add it to the corresponding article, otherwise add it to the corresponding subarticle
        if(article_return.subarticle_id == 0) {
            findAndAddReturn(articles, article_return);
        } else {
            for(let articleId in subarticles) {
                findAndAddReturn(subarticles[articleId], article_return);
            }            
        }
    }

    delete articles_data.returns;

    return articles_data;
}

export const rearangeArticlesData = (articles_data) => {
    // first add handouts and returns to articles and subarticles
    // this way the for below will copy the subarticles already containing this information
    const articlesDataStep1 = rearangeHandoutsData(articles_data);
    const articlesData = rearangeReturnsData(articlesDataStep1);

    const articles = articlesData.articles;
    const subarticles = articlesData.subarticles;

    // the subarticles are mapped to the article id
    for(let articleId in subarticles) {
        // we iterate the subarticles of an article to be able to match it to the main instance - also for recurrent articles
        for(let subarticle of subarticles[articleId]) {
            const i = articles.findIndex(article => article.a_id == articleId && article.recurrence_pos == subarticle.recurrence_pos && article.recurring_id == subarticle.recurring_id);

            if(i !== -1) {
                if(!articles[i].subarticles) {
                    articles[i].subarticles = [];
                }

                articles[i].subarticles = [...articles[i].subarticles, subarticle];
            }
        }
    }

    delete articlesData.subarticles;

    return articlesData;
}

export const prepareCategoriesData = (categories) => {
    categories.map( (category) => {
        let children = category.children;
        let details = category.Category;
        
        if(children && children.length) {
            prepareCategoriesData(children);
        }
        category.label = details.kat;
        category.value = details.idkat;
        category.filterable = details.filterable === "0" ? false : true;
        category.expanded = true;
    });

    return categories;
}

export const prepareLocationsData = (locations) => {
    locations.map( (location) => {
        location.label = location.name;
        location.value = location.id;
    });

    return locations;
}