import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import ActionButton from "../../common/components/ActionButton";

import ArticlesTableHeader from "./ArticlesTableHeader";
import ArticlesTableContent from "./ArticlesTableContent";

import ScanArticlesPopup from "./ScanArticlesPopup";

import lang from "../../common/lang/de.json";

import { isRecurrentReservation } from './ArticlesTableEntry/ArticlesUtils';

import {
    selectIsReservation,
    selectReservationCustomer,
    selectReservationArticles,
    addNewArticle,
    convertDateForServer
} from '../store/reservationSlice';

import Popup from "../../common/components/Popup";

const ReservationArticles = () => {
    const dispatch = useDispatch();

    const isReservation = useSelector(selectIsReservation);
    const reservationCustomer = useSelector(selectReservationCustomer);
    const articles = useSelector(selectReservationArticles);

    const [showScanArticlesPopup, setShowScanArticlesPopup] = useState(false);
    const [showScanIsNotPossible, setShowScanIsNotPossible] = useState(false);

    const onArticleSelected = (article_id, from, to) => {
        const fromForServer = convertDateForServer(from);
        const toForServer = convertDateForServer(to);

        dispatch(addNewArticle({article_id, from: fromForServer, to: toForServer}));
    }
    
    const onAddArticle = () => {
        let articleTenantId = null;
        let customerTenantId = reservationCustomer.tenant_id;
        console.log("customerTenantId: ", customerTenantId);
        if(articles && articles.length) {
            articleTenantId = articles[0].tenant_id;
        }
        console.log("articleTenantId: ", articleTenantId);
        window.openArticleTable(customerTenantId, articleTenantId, articles.map(a => a.a_id) /* articles already in contract */, onArticleSelected);
    }

    const onShowScanArticlesPopup = () => {
        if(isRecurrentReservation(articles)) {
            setShowScanIsNotPossible(true);
        } else {
            setShowScanArticlesPopup(true);
        }
    };

    return (
        <div className="articles_wrapper dash-block-container">
            <div className="article_buttons">
                <ActionButton onClick={onAddArticle} text={lang.reservation.add_article} />

                {isReservation &&
                    <ActionButton onClick={onShowScanArticlesPopup} text={lang.reservation.scan_articles} className="right_block" />
                }

                {showScanArticlesPopup &&
                    <ScanArticlesPopup onCancelClicked={() => setShowScanArticlesPopup(false)} />
                }

                {showScanIsNotPossible &&
                    <Popup title={lang.reservation.scan_articles} showButtons={false} onCancelClicked={() => setShowScanIsNotPossible(false)}>
                        <div className="info">
                            {lang.reservation.scan_articles_not_possible_for_reccurent_reservations}                            
                        </div>
                    </Popup>
                }
            </div>

            <div className="articles qv_table">
                <ArticlesTableHeader />

                <ArticlesTableContent />
            </div>
        </div>
    );
}

export default ReservationArticles;