
export const isRecurrentReservation = (articles) => {
    for (let article of articles) {
        // search subarticles
        if (article.has_subarticles) {
            const subarticle = article.subarticles.find(
                s => s.recurring_id != 0 && s.recurrence_pos !== 0,
            );

            // found a subarticle
            if (subarticle) {
                return true;
            }
        }

        // found an article
        if (article.recurring_id != 0 && article.recurrence_pos !== 0) {
            return true;
        }
    }

    return false;
}

export const findByArticleNumber = (articles, articleNumberToFind) => {
    for (let article of articles) {
        // search subarticles
        if (article.has_subarticles) {
            const subarticle = article.subarticles.find(
                s => s.gnr === articleNumberToFind,
            );

            // found a subarticle
            if (subarticle) {
                return { foundArticle: article, foundSubarticle: subarticle };
            }
        }

        // found an article
        if (article.gnr === articleNumberToFind) {
            return { foundArticle: article, foundSubarticle: null };
        }
    }

    return { foundArticle: null, foundSubarticle: null };
};

export const getSubarticleFullNumber = (articleNumber, subarticlePrefix, subarticleNumber) => {
    let subarticleFullNumber = (subarticlePrefix && subarticlePrefix !== '' ? subarticlePrefix.toUpperCase() : articleNumber.toUpperCase());
    if(subarticleNumber !== '') {
        subarticleFullNumber = subarticleFullNumber + "-" + subarticleNumber;
    }

    return subarticleFullNumber;
}
