import lang from "../../common/lang/de.json";
import { reportsCommonLang, commonColumns } from '../common/config/ReportsCommonConfig'

export const rentalHistoryLang = lang.reports.rental_history;

export const columns = [
    commonColumns["contract_number"],
    commonColumns["article_number"],
    commonColumns["article_name"],
    commonColumns["quantity"],
    commonColumns["customer_name"],
    commonColumns["start_date"],
    commonColumns["end_date"],
    commonColumns["contract_status"]
];

export const filterByColumns = [
    {
        label: rentalHistoryLang.header.filter_by_columns.article_number,
        value: "gnr"
    },
    {
        label: rentalHistoryLang.header.filter_by_columns.customer,
        value: "customer",
    }
];