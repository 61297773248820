import React, { Component } from "react";
import { connect } from "react-redux";
import "../../common/scss/common.scss";
import "./RentalHistoryApp.scss";
import { rentalHistoryLang } from './RentalHistoryConfig'

import PageTitle from '../common/components/PageTitle'
import RentalHistoryDataTable from "./RentalHistoryDataTable";
import RentalHistoryDataTableHeader from "./RentalHistoryDataTableHeader";

import { getRentalHistoryData, getRentalHistoryTotalRows } from "./store/rentalHistorySlice";
import PageSection from "../../common/components/PageSection";

class RentalHistoryApp extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        // eslint-disable-next-line react/prop-types
        this.props.getRentalHistoryData();
        this.props.getRentalHistoryTotalRows();
    }

    render() {
        return (
            <div id="rental_history_app_content" className="col-one-full">
                <PageTitle title={rentalHistoryLang.header.title} />

                { false && <PageSection>
                    <RentalHistoryDataTableHeader />
                    <RentalHistoryDataTable />
                </PageSection> }
                { true && <div className="page-section data-table-block dash-block-container data-table-block-extended data-table-block-margins position-relative ">
                    <RentalHistoryDataTableHeader />
                    <div className="table_wrapper">
                        <RentalHistoryDataTable />
                    </div>
                </div> }
            </div>
        );
    }
}

export default connect(null, { getRentalHistoryData, getRentalHistoryTotalRows })(RentalHistoryApp);
