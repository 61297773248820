import { client } from '../../common/api/client';

const baseApiUrl = "/includes/reservation/api/index.php?method=";

function getApiUrl(action) {
    return baseApiUrl + action;
}

class ReservationApi {

    static newContractFor(customer_id, contract_type) {
        return client.post(getApiUrl("newContractFor"), {customer_id, contract_type});
    }

    static getData(reservation_id) {
        return client.post(getApiUrl("getData"), {reservation_id});
    }

    static addNewArticle(reservation_id, article_id, from, to, subarticles_already_in_contract) {
        return client.post(getApiUrl("addNewArticle"), {reservation_id, article_id, from, to, subarticles_already_in_contract});
    }

    static setNewCustomer(reservation_id, customer_id) {
        return client.post(getApiUrl("setNewCustomer"), {reservation_id, customer_id});
    }

    static setArticleDates(reservation_id, article_id, subarticle_id, from, to, recurring_id, recurring_pos) {
        return client.post(getApiUrl("setArticleDates"), {reservation_id, article_id, subarticle_id, from, to, recurring_id, recurring_pos});
    }

    static setArticleCount(reservation_id, article_id, count, recurring_id, recurring_pos) {
        return client.post(getApiUrl("setArticleCount"), {reservation_id, article_id, count, recurring_id, recurring_pos});
    }

    static removeArticleFromContract(reservation_id, article_id, subarticle_id, recurring_id, recurring_pos) {
        return client.post(getApiUrl("removeArticleFromContract"), {reservation_id, article_id, subarticle_id, recurring_id, recurring_pos});
    }

    static anonymize(reservation_id) {
        return client.post(getApiUrl("anonymize"), {reservation_id});
    }

    static convertRequestToReservation(request_id) {
        return client.post(getApiUrl("convertRequestToReservation"), {request_id});
    }

    static changeReservationStatus(reservation_id, status) {
        return client.post(getApiUrl("changeReservationStatus"), {reservation_id, status});
    }

    static addNewHandout(reservation_id, article_id, subarticle_id, count, day, recurring_id, recurring_pos) {
        return client.post(getApiUrl("addNewHandout"), {reservation_id, article_id, subarticle_id, count, day, recurring_id, recurring_pos});
    }

    static changeHandout(reservation_id, handout_id, article_id, subarticle_id, count, day, recurring_id, recurring_pos) {
        return client.post(getApiUrl("changeHandout"), {reservation_id, handout_id, article_id, subarticle_id, count, day, recurring_id, recurring_pos});
    }

    static addNewReturn(reservation_id, article_id, subarticle_id, count, day, recurring_id, recurring_pos) {
        return client.post(getApiUrl("addNewReturn"), {reservation_id, article_id, subarticle_id, count, day, recurring_id, recurring_pos});
    }

    static changeReturn(reservation_id, return_id, article_id, subarticle_id, count, day, recurring_id, recurring_pos) {
        return client.post(getApiUrl("changeReturn"), {reservation_id, return_id, article_id, subarticle_id, count, day, recurring_id, recurring_pos});
    }

    static getScanArticlesQRCode(reservation_id, operation) {
        return client.get("/includes/reservation/api/ScanArticlesQRCode.php?method=generate&id=" + encodeURIComponent(reservation_id) +
            "&operation=" + encodeURIComponent(operation));
    }

    static getCategories(customerTenantId) {
        return client.post(getApiUrl("getCategories"), {customerTenantId});
    }

    static getSpeziForArticles(customerTenantId, articleTenantId, start_date, start_time, end_date, end_time, filter, specFilter, locationId, categoryId) {
        return client.post(getApiUrl("getSpeziForArticles"), {customerTenantId, articleTenantId, start_date, start_time, end_date, end_time, filter, specFilter, locationId, categoryId});
    }

    static getLocationsForTenant(customerTenantId) {
        return client.post(getApiUrl("getLocationsForTenant"), {customerTenantId});
    }
}

export default ReservationApi;
