/* global contract_type:writable, moment */

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import ReservationApi from "../api/ReservationApi";
import { isTypeReservation } from "../ReservationType";
import { rearangeArticlesData } from "./rearangeArticlesData";

const initialState = {
    status: 'idle',
    error: null,

    reservation_id: 0,
    reservation_data: {}
};

export const convertDateForServer = (uiDate) => {
    const date = moment(uiDate, "DD.MM.YYYY HH:mm")
    return date.format("YYYY-MM-DDTHH:mm");
}


export const getReservation = createAsyncThunk(
    'reservation/getDetails',
    async (_noParams, { getState }) => {
        const reservation_id = getState().reservation.reservation_id;

        const response = await ReservationApi.getData(reservation_id);
        return response.reservation_data;
    }
)

export const addNewArticle = createAsyncThunk(
    'reservation/addNewArticle',
    async ({article_id, from, to, subarticles_already_in_contract}, { getState }) => {
        const state = getState();
        const reservation_id = selectReservationId(state);

        const response = await ReservationApi.addNewArticle(reservation_id, article_id, from, to, subarticles_already_in_contract);
        return response;
    }
)

export const setNewCustomer = createAsyncThunk(
    'reservation/setNewCustomer',
    async (customer_id, { getState }) => {
        const state = getState();
        const reservation_id = selectReservationId(state);

        const response = await ReservationApi.setNewCustomer(reservation_id, customer_id);
        return response;
    }
)

export const setArticleDates = createAsyncThunk(
    'reservation/setArticleDates',
    async ({article_id, subarticle_id, from, to, recurring_id, recurrence_pos}, { getState }) => {
        const state = getState();
        const reservation_id = selectReservationId(state);

        const response = await ReservationApi.setArticleDates(reservation_id, article_id, subarticle_id, from, to, recurring_id, recurrence_pos);
        return response
    }
)

export const setArticleCount = createAsyncThunk(
    'reservation/setArticleCount',
    async ({article_id, new_count, recurring_id, recurrence_pos}, { getState }) => {
        const state = getState();
        const reservation_id = selectReservationId(state);

        const response = await ReservationApi.setArticleCount(reservation_id, article_id, new_count, recurring_id, recurrence_pos);
        return response
    }
)

export const removeArticleFromContract = createAsyncThunk(
    'reservation/removeArticleFromContract',
    async ({article_id, subarticle_id, recurring_id, recurring_pos}, { getState }) => {
        const state = getState();
        const reservation_id = selectReservationId(state);

        const response = await ReservationApi.removeArticleFromContract(reservation_id, article_id, subarticle_id, recurring_id, recurring_pos);
        return response
    }
)

export const anonymize = createAsyncThunk(
    'reservation/anonymize',
    async (_params, { getState }) => {
        const state = getState();
        const reservation_id = selectReservationId(state);

        const response = await ReservationApi.anonymize(reservation_id);
        return response
    }
)

export const convertRequestToReservation = createAsyncThunk(
    'reservation/convertRequestToReservation',
    async (_params, { getState }) => {
        const state = getState();
        const reservation_id = selectReservationId(state);

        const response = await ReservationApi.convertRequestToReservation(reservation_id);
        return response
    }
)

export const changeReservationStatus = createAsyncThunk(
    'reservation/changeReservationStatus',
    async ({ status }, { getState }) => {
        const state = getState();
        const reservation_id = selectReservationId(state);

        const response = await ReservationApi.changeReservationStatus(reservation_id, status);
        return response
    }
)

export const addNewHandout = createAsyncThunk(
    'reservation/addNewHandout',
    async ({article_id, subarticle_id, count, day, recurring_id, recurrence_pos}, { getState }) => {
        const state = getState();
        const reservation_id = selectReservationId(state);

        const response = await ReservationApi.addNewHandout(reservation_id, article_id, subarticle_id, count, day, recurring_id, recurrence_pos);
        return response
    }
)

export const changeHandout = createAsyncThunk(
    'reservation/changeHandout',
    async ({handout_id, article_id, subarticle_id, count, day, recurring_id, recurrence_pos}, { getState }) => {
        const state = getState();
        const reservation_id = selectReservationId(state);

        const response = await ReservationApi.changeHandout(reservation_id, handout_id, article_id, subarticle_id, count, day, recurring_id, recurrence_pos);
        return response
    }
)

export const addNewReturn = createAsyncThunk(
    'reservation/addNewReturn',
    async ({article_id, subarticle_id, count, day, recurring_id, recurrence_pos}, { getState }) => {
        const state = getState();
        const reservation_id = selectReservationId(state);

        const response = await ReservationApi.addNewReturn(reservation_id, article_id, subarticle_id, count, day, recurring_id, recurrence_pos);
        return response
    }
)

export const changeReturn = createAsyncThunk(
    'reservation/changeReturn',
    async ({return_id, article_id, subarticle_id, count, day, recurring_id, recurrence_pos}, { getState }) => {
        const state = getState();
        const reservation_id = selectReservationId(state);

        const response = await ReservationApi.changeReturn(reservation_id, return_id, article_id, subarticle_id, count, day, recurring_id, recurrence_pos);
        return response
    }
)

// sets articles and history from response
const setStateArticleDataFromResponse = (response, state) => {
    if(response.success === true) {
        const reservation_articles = response.reservation_articles;

        const articles_data = rearangeArticlesData(reservation_articles);
        state.reservation_data.articles = articles_data.articles;
        state.reservation_data.history = response.history;

        // also set the header since the handout/return status for the reservations might change
        state.reservation_data.header = response.header;
    }
}

export const slice = createSlice({
    name: 'reservation',
    initialState,

    reducers: {
        setReservationId: (state, action) => {
            state.reservation_id = action.payload;
        }
    },

    extraReducers: {
        [getReservation.pending]: (state) => {
            state.status = 'loading';
        },
        [getReservation.fulfilled]: (state, action) => {
            state.status = 'succeeded';

            state.reservation_data = rearangeArticlesData(action.payload);

            // used in some functions from functions.js
            window.contract_type = state.reservation_data.header.type;
            window.idcontract = state.reservation_data.header.id;
        },
        [getReservation.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
        },

        [addNewArticle.fulfilled]: (state, action) => {
            const response = action.payload

            if(response.success === true) {
                setStateArticleDataFromResponse(response, state);
            } else {

                // this response comes when a new subarticle could not be added for the requested interval, but there are subarticles available for different intervals
                // the received subarticle will be shown to the user with a marking that is not available, the user can change to different dates, if the subarticle is available it will be saved
                if(response.success === "ok_for_alternative_interval") {

                    // add this to the subarticles collection of the parent article
                    const articleIndex = state.reservation_data.articles.findIndex(a => a.a_id == response.not_saved_subarticle.article_id && a.recurring_id == 0 && a.recurrence_pos == 0);
                    if(articleIndex !== -1) {
                        const article = state.reservation_data.articles[articleIndex];
                        article.subarticles.push(response.not_saved_subarticle);
                    }
                }
            }
        },
        

        [setNewCustomer.fulfilled]: (state, action) => {
            if(action.payload.success) {
                state.reservation_data.customer = action.payload.customer;
                state.reservation_data.history = action.payload.history;
            }            
        },

        // to do: change the api to returen the whole articles, this way the code here would be the same as in removeArticleFromContract.fulfilled
        [setArticleDates.fulfilled]: (state, action) => {
            const {article_id, subarticle_id, from, to, recurring_id, recurring_pos} = action.payload.dates_changed;

            const articleIndex = state.reservation_data.articles.findIndex(a => a.a_id == article_id && a.recurring_id == recurring_id && a.recurrence_pos == recurring_pos);
            if(articleIndex !== -1) {
                const article = state.reservation_data.articles[articleIndex];

                // if this is an articles change the dates at article level, otherwise change it for the subarticle
                if(subarticle_id === 0) {

                    article.a_from = from;
                    article.a_to = to;
                } else {

                    const subarticleIndex = article.subarticles.findIndex(sa => sa.article_id == article_id && sa.subarticle_id == subarticle_id && 
                        sa.recurring_id == recurring_id && sa.recurrence_pos == recurring_pos);
                    if(subarticleIndex !== -1) {
                        if(article.subarticles[subarticleIndex].not_saved) {
                            article.subarticles[subarticleIndex].not_saved = false; // since the dates where set, the subarticle was saved
                        }

                        article.subarticles[subarticleIndex].start_date = from;
                        article.subarticles[subarticleIndex].end_date = to;
                    }
                }

                state.reservation_data.history = action.payload.history;
            }
        },

        [setArticleCount.fulfilled]: (state, action) => {
            const response = action.payload;

            setStateArticleDataFromResponse(response, state);
        },

        [removeArticleFromContract.fulfilled]: (state, action) => {
            const response = action.payload;

            setStateArticleDataFromResponse(response, state);
        },

        [anonymize.fulfilled]: (state, action) => {
            const response = action.payload;

            if(response.success === true) {
                state.reservation_data.header.anonymous = 1;
                state.reservation_data.customer = action.payload.customer;
                state.reservation_data.history = action.payload.history;
            }
        },

        [convertRequestToReservation.fulfilled]: (state, action) => {
            const response = action.payload;

            if(response.success === true) {
                window.location.replace(response.redirect_to);
            }
        },

        [changeReservationStatus.fulfilled]: (state, action) => {
            const response = action.payload;

            if(response.success === true) {
                state.reservation_data.header = response.header;
                state.reservation_data.history = response.history;
            }
        },
        
        [addNewHandout.fulfilled]: (state, action) => {
            const response = action.payload;

            setStateArticleDataFromResponse(response, state);
        },

        [changeHandout.fulfilled]: (state, action) => {
            const response = action.payload;

            setStateArticleDataFromResponse(response, state);
        },

        [addNewReturn.fulfilled]: (state, action) => {
            const response = action.payload;

            setStateArticleDataFromResponse(response, state);
        },

        [changeReturn.fulfilled]: (state, action) => {
            const response = action.payload;

            setStateArticleDataFromResponse(response, state);
        }
    }
});

export const { setReservationId } = slice.actions;

export const selectReservationId = state => state.reservation.reservation_id;
export const selectReservationHeader = state => state.reservation.reservation_data.header;
export const selectReservationCustomer = state => state.reservation.reservation_data.customer;
export const selectReservationArticles = state => state.reservation.reservation_data.articles;
export const selectReservationHistory = state => state.reservation.reservation_data.history;
export const selectReminders = state => state.reservation.reservation_data.reminders;
export const selectCanAnonymize = state => state.reservation.reservation_data.can_anonymize;

export const selectIsReservation = (state) => {
    const reservationHeader = state.reservation.reservation_data.header;
    const isReservation = reservationHeader ? isTypeReservation(reservationHeader.type) : false;

    return isReservation;
}

export default slice.reducer;